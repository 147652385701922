/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2022 Colophon Foundry
 *
 * Licenced to Rebecca Materne-Feiden Jungwinzer und Schr&ouml;terzunft Winningen eV
 */

@font-face {
    font-family: 'system85-medium';
    src: url('./system85-medium.eot');
    src: url('./system85-medium.eot?#iefix') format('embedded-opentype'),
         url('./system85-medium.woff2') format('woff2'),
         url('./system85-medium.woff') format('woff'),
         url('./system85-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
